import { HashRouter as Router, Routes, Route } from "react-router-dom";

import Home from './pages/home';
import Projects from './pages/projects';
import Termos from './pages/terms';
import Privacy from './pages/privacy';
import Error from './pages/error';

import ScrollToTop from "./components/ScrollToTop";

import './main.css';


function App() {

  return (
    <Router>
      <ScrollToTop/>
        <Routes>
          <Route path="/" rel="preload" element={<Home />} ></Route>
          <Route path="/atelier&projetos" rel="preload" element={<Projects />} ></Route>
          <Route path="/termos&condições" rel="preload" element={<Termos />} ></Route>
          <Route path="/política-de-privacidade" rel="preload" element={<Privacy />} ></Route>
          <Route path="*" rel="preload" element={<Error />} ></Route>
        </Routes>
    </Router>
  );
}

export default App;
