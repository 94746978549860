import React, { useEffect, useState } from 'react';

import Carousel from 'react-bootstrap/Carousel';

function Carousels({ className }) {
  const [carouselData, setCarouselData] = useState([]);

  const urlBase = 'https://carolinafreitas.pt/wp/wp-json/wp/v2/';
  let endpoint = '';

  if (className === 'C-Container') {
    endpoint = 'slide-hp?per_page=100';
  } else if (className === 'P-Container') {
    endpoint = 'slide-atelier?per_page=100';
  }

  useEffect(() => {
    console.log(urlBase+endpoint);
    fetch(urlBase+endpoint)
      .then((response) => response.json())
      .then((data) => setCarouselData(data))
      .catch((error) => console.error('Error fetching data: ', error));
  }, [urlBase, endpoint]);

  return (
    <div className={className}>
      <Carousel controls={false} fade={true}>
        {carouselData.map((slide, index) => (
          <Carousel.Item key={index} interval={7000}>
            <p>{slide.acf.nm_prjt}</p>
            <img fetchpriority="high" src={slide.acf.img} width='100%' height='auto' alt={slide.acf.nm_prjt} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  )
}

export default Carousels