import { useEffect } from "react";

import '../main.css';

import Header from "../components/Header";
import Footer from "../components/Footer";

function Error() {

  useEffect(() => {
    document.title = "Error 404";
  }, []);

  return (
    <div className="App">
      <div className="E-Container">
      <Header srcL={'https://carolinafreitas.pt/wp/wp-content/uploads/2023/10/lgvectPB.svg'} srcR={'https://carolinafreitas.pt/wp/wp-content/uploads/2023/10/lgtxtPB.svg'}></Header>
        <div id="error">
          <h1>ERRO 404</h1>
          <span>Volte para a homepage</span>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Error;
