import { useEffect, useState } from 'react';

export const Footer = () => {

    const [FData, setFData] = useState([]);

    useEffect(() => {
        fetch('https://carolinafreitas.pt/wp/wp-json/wp/v2/footer')
            .then((response) => response.json())
            .then((data) => setFData(data))
            .catch((error) => console.error('Error fetching data: ', error));
    }, []);
    return (
        <footer>

            <div>

                <div>
                    <span>CAROLINA FREITAS ARQUITECTURA © 2023 </span>
                </div>

                <div>
                    <ul>
                        {FData.map((info, index) => (
                            <li key={index}> <a href={info.acf.link} target="_blank" rel="preload noopener noreferrer">LIVRO DE RECLAMAÇÕES </a></li>
                        ))}
                        <li> . </li>
                        <li> <a href="#/política-de-privacidade" target="_blank" rel="preload noopener noreferrer"> POLÍTICA DE PRIVACIDADE </a> </li>
                        <li> . </li>
                        <li> <a href="#/termos&condições" target="_blank" rel="preload noopener noreferrer">TERMOS E CONDIÇÕES </a></li>
                    </ul>
                </div>

            </div>

        </footer>
    )
}

export default Footer