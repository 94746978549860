import { useEffect, useState } from 'react';

import '../main.css';

import Header from "../components/Header";
import Footer from "../components/Footer";

function Privacy() {

    useEffect(() => {
        document.title = "Política de Privacidade";
    }, []);

    const [TCData, setTCData] = useState([]);

    useEffect(() => {
        fetch('https://carolinafreitas.pt/wp/wp-json/wp/v2/footer')
            .then((response) => response.json())
            .then((data) => setTCData(data))
            .catch((error) => console.error('Error fetching data: ', error));
    }, []);

    return (
        <div className="App">
            <div className="E-Container">
            <Header srcL={'https://carolinafreitas.pt/wp/wp-content/uploads/2023/10/lgvectPB.svg'} srcR={'https://carolinafreitas.pt/wp/wp-content/uploads/2023/10/lgtxtPB.svg'}></Header>
                <div className='info-Container' style={{marginTop: 0}}>

                    {TCData.map((info, index) => (
                        <div key={index}>
                            <p dangerouslySetInnerHTML={{ __html: info.acf.pol_priv }}></p>
                        </div>
                    ))}
                </div>
                <Footer></Footer>
            </div>
        </div>
    );
}

export default Privacy;
