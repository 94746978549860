import { useEffect, useState } from 'react';

import '../main.css';

import Header from "../components/Header";
import Carousels from "../components/Carousel";
import Footer from "../components/Footer";

function Projects() {

  useEffect(() => {
    document.title = "Atelier/Projetos";
  }, []);

  const [APData, setAPData] = useState([]);

  useEffect(() => {
    fetch('https://carolinafreitas.pt/wp/wp-json/wp/v2/atelier')
      .then((response) => response.json())
      .then((data) => setAPData(data))
      .catch((error) => console.error('Error fetching data: ', error));

    if (PData.length === 0) {
      fetchData();
    }

  }, []);

  const [PData, setPData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch('https://carolinafreitas.pt/wp/wp-json/wp/v2/projeto?per_page=100');
      const data = await response.json();
      setPData(data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  return (
    <div className="App">
      <div className='HeroP'>
        <Header srcL={'https://carolinafreitas.pt/wp/wp-content/uploads/2023/10/lgvect.svg'} srcR={'https://carolinafreitas.pt/wp/wp-content/uploads/2023/10/lgtxt.svg'}></Header>
        <Carousels rel="preload" className='P-Container'></Carousels>
      </div>

      <div rel="preload" className="AP-info-Container">
        <div>
          <div id="nav">
            <ul>
              <li><a href={`${window.location.hash}#atelier`} onClick={(e) => {
                e.preventDefault(); // Prevent the default behavior of the anchor link
                const element = document.getElementById('atelier');
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }}>ATELIER</a></li>
              <li> . </li>
              <li> <a href={`${window.location.hash}#projetos`} onClick={(e) => {
                e.preventDefault(); // Prevent the default behavior of the anchor link
                const element = document.getElementById('projetos');
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }}>PROJETOS</a></li>
            </ul>
          </div>

          <div className="Atelier">
            <h1 id="atelier"> ATELIER</h1>
            {APData.map((info, index) => (
              <div id="atxt" key={index}>
                <p dangerouslySetInnerHTML={{ __html: info.acf.txt_al }}></p>
                <p dangerouslySetInnerHTML={{ __html: info.acf.txt_ar }}></p>
              </div>
            ))}
          </div>

          <div className="Projects">
            <h1 id="projetos"> PROJETOS</h1>

            <div id="plist">
              {PData.map((info, index) => (
                <div className="build" key={index}>
                  <h4>{info.acf.nm_prjt}</h4>
                  <ul>
                    <li> {info.acf.ai}</li>
                    <li> .</li>
                    <li> {info.acf.af}</li>
                    <li> .</li>
                    <li> {info.acf.tp_prjt}</li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Projects;
