import { Link } from "react-router-dom"

export const Header = ({srcR, srcL}) => {
  return (
    <header>
         <div>
            <div><Link to="/"><img src={srcL} width='100%' height='auto' alt=""/></Link></div>
            <div><Link to="/"><img src={srcR} width='100%' height='auto' alt=""/></Link></div>
        </div>
    </header>
  )
}

export default Header