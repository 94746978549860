import { useEffect, useState } from 'react';

import '../main.css';

import Header from "../components/Header";
import Carousels from "../components/Carousel";
import Footer from "../components/Footer";

function Home() {

  useEffect(() => {
    document.title = "Carolina Freitas Arquitectura";
  }, []);

  const [homepageData, setHomepageData] = useState([]);

  useEffect(() => {
    fetch('https://carolinafreitas.pt/wp/wp-json/wp/v2/homepage')
      .then((response) => response.json())
      .then((data) => setHomepageData(data))
      .catch((error) => console.error('Error fetching data: ', error));
  }, []);

  return (
    <div className="App">
      <div className='Hero'>
      <Header srcL={'https://carolinafreitas.pt/wp/wp-content/uploads/2023/10/lgvect.svg'} srcR={'https://carolinafreitas.pt/wp/wp-content/uploads/2023/10/lgtxt.svg'}></Header>
        <Carousels rel="preload" className='C-Container'></Carousels>
      </div>

      <div className='info-Container'>

        {homepageData.map((info, index) => (
          <div rel="preload" key={index}>
            <p dangerouslySetInnerHTML={{ __html: info.acf.txt_hp }}></p>
          </div>
        ))}

        <div>
          <h1><a href='#/atelier&projetos'>ATELIER / PROJETOS </a></h1>
        </div>


        <div id='line'>
          <h3>CONTACTOS</h3>
        </div>

        {homepageData.map((info, index) => (
          <div className="contact-info" id='contact' key={index}>
            <span> <a href={`tel:${info.acf.contactos.nr_tlm}`}>{info.acf.contactos.nr_tlm} </a></span>
            <span> <a href={`tel:${info.acf.contactos.nr_fix}`}>{info.acf.contactos.nr_fix}</a></span>
          </div>
        ))}

        {homepageData.map((info, index) => (
          <div className="contact-info" key={index}>
            <span> <a href={`mailto:${info.acf.contactos.email}`} >{info.acf.contactos.email}</a></span>
          </div>
        ))}

        {homepageData.map((info, index) => (
          <div className="contact-info" key={index}>
            <a href='https://maps.app.goo.gl/DF9kCULqQyWKhRZb9' target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: info.acf.contactos.morada }}></a>
          </div>
        ))}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Home;
